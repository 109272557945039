import React from 'react';

class Status extends React.Component {
  getStatusColor = (status) => {
    const statuses = [
      'To Do',
      'In Progress',
      'Blocked',
      'Completed',
      'Cancelled',
      'Need More Information',
      'Reopened'
    ];

    let hue;

    switch (status) {
      case 'To Do':
        hue = 0; // Red hue
        break;
      case 'In Progress':
          hue = 0.33; // Green hue
          return `rgb(11, 160, 17)`;
      case 'Completed':
        return '#2dccd3'; // Fixed color for 'Completed'
      case 'Need More Information':
        hue = 0.083; // Orangy hue
        break;
      default:
        // For other statuses, calculate complementary colors to #2dccd3
        const completedHue = 0.5; // Hue for '#2dccd3' (teal)
        const statusNumber = statuses.indexOf(status);
        const diff = completedHue - statusNumber * 0.1; // Adjust the difference in hue
        hue = ((completedHue + diff + 1) % 1) * 360; // Calculate complementary hue
        break;
    }

    const complementaryColor = `hsl(${hue}, 100%, 50%)`;
    return complementaryColor;
  };

  render() {
    const { status, style } = this.props;
    const statusColor = this.getStatusColor(status);
    const combinedStyle = {
      color: statusColor,
      ...style // Merging provided style props
    };

    return (
      <td className="cart-ttl" style={combinedStyle}>{status}</td>
    );
  }
}

export default Status;
