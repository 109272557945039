import React, { useEffect } from "react";
import logo from "./img/logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { faTicketAlt } from "@fortawesome/free-solid-svg-icons";

function LogoHeader2() {
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token");

  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.setItem('token', '');

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("prevUrl", location.pathname);
    navigate(`/login?${currentUrl.searchParams}`);
  };

  return (
    <header className="header">
    <div className="header-middle">
      <div className="container header-middle-cont">
        <div className="toplogo">
          <a href="/">
            <img src={logo} alt="Big Sisters of BC Lower Mainland" />
          </a>
        </div>
        <div className="shop-menu social-icons">
          <ul>
          <li>
              <a href="/tickets">
              <FontAwesomeIcon icon={faTicketAlt} />&nbsp;
                <span className="shop-menu-ttl">Add Ticket Form</span>
              </a>
            </li>
            <li>
              <a href="/admin">
              <FontAwesomeIcon icon={faTicket} />&nbsp;
                <span className="shop-menu-ttl">Task Admin</span>
              </a>
            </li>

            <li>
              <a href="/task-manager">
              <FontAwesomeIcon icon={faReceipt} />&nbsp;
                <span className="shop-menu-ttl">Task Categories</span>
              </a>
            </li>
            <li>
              <a href="/requested-by">
              <FontAwesomeIcon icon={faPeopleGroup} />&nbsp;
                <span className="shop-menu-ttl">Requestors</span>
              </a>
            </li>

            {token && <>
              <li>
              <FontAwesomeIcon icon={faEllipsisVertical} style={{color: "#b0b1b3"}} />&nbsp;
                <span className="shop-menu-ttl"></span>
            </li>
            <li className="stylization">
              <a onClick={handleLogout} style={{cursor: "pointer"}}>
              <FontAwesomeIcon icon={faSignOut} />&nbsp;
                <span className="shop-menu-ttl">Log Out</span>
              </a>
            </li>            
            </>}
          </ul>
        </div>
      </div>
    </div>
    </header>
  );
}

export default LogoHeader2;