import React, { Component } from "react";
import "./css/TaskForm.css";
import ResponseText from "./Response";

class TaskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseText: "",
    };
  }

  handleResponseText = (value) => {
    this.setState({ responseText: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {responseText} = this.state;
    const {editedItem, toast, fetchData} = this.props;
    const identifier = editedItem.ID;

    if (!responseText) {
      toast.error("Response cannot be empty!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/tasks/response`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ID: identifier,
        Response: responseText,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        toast.success("Response added!", {
          position: toast.POSITION.TOP_CENTER,
        });

        fetchData();
        this.setState({responseText: ""});
      })
      .catch((error) => {
        toast.error(`Error: ${error}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  

  render() {
    const {
      editedItem,
      taskError,
      isLoading,
    } = this.props;

    const chunkArrayBySizes = (array, sizes) => {
      const results = [];
      let startIndex = 0;
      sizes.forEach((size) => {
        const endIndex = startIndex + size;
        results.push(array.slice(startIndex, endIndex));
        startIndex = endIndex;
      });
      return results;
    };

    const renderFormFields = (fields) =>
      fields.map(([key, value]) => {

        if (key === "isDeleted") {
          return null;
        }

        return (
          <p key={key}>
            <label htmlFor={`Update_${key}`}>{key.replace(/_/g, " ")}:</label>
            {key === "AdditionalNotes" || key === "Response"? (
              <div
                id={`${key}`}
                name={`${key}`}
                dangerouslySetInnerHTML={{ __html: value }}
              />
            ) : (
              <input
                type="text"
                id={`${key}`}
                name={`${key}`}
                value={value}
                readOnly={true}
              />
            )}
          </p>
        );
      }
    
    );

    const formFields = editedItem ? Object.entries(editedItem) : [];
    let chunkSizes = [15];

    const chunkedFields = chunkArrayBySizes(formFields, chunkSizes);

    const {responseText} = this.state;



    return (
      <div className="auth-col">
        <form onSubmit={this.handleSubmit} className="login">
          {chunkedFields.map((fields, index) => (
            <div key={index} className="well">
              {renderFormFields(fields)}
            </div>
          ))}
            <p style={{fontSize: "1.5rem", marginBottom: "10px"}}>Add a Response:
            </p>
          <ResponseText
            text={this.state.responseText}
            handleChange={this.handleResponseText}
          />

          <p className="auth-submit">
            <input type="submit" value="Add Response" disabled={isLoading} />
          </p>
        </form>

        {taskError && (
          <div className="err404">
            <p className="err404-search">{taskError}</p>
          </div>
        )}
      </div>
    );
  }
}

export default TaskForm;