import React, { Component } from "react";
import "./css/Pledges.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Handshake from "./img/handshake.png";
import Spinner from "./Spinner";
import RequestedBy from "./RequestedBy";
import TaskDropdown from "./Task";
import AdditionalNotes from "./AdditionalNotes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Status from './Status';
import axios from "axios";
import Form from "react-bootstrap/Form";
import { STATUSES } from "./const";

class Tickets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      data: [],
      isLoading: false,
      requestedBy: "",
      task: "",
      additionalNotes: "",
      donorFirstName: "",
      donorLastName: "",
      nxtLink: "",
      filename: "",
      tasks: [],
      statuses: ["All", ...STATUSES], 
      status: "All",
      requestors: [],
      title: "",
      categories: []

    };
  }

    // Method to fetch tasks
    fetchTasks = () => {
      fetch(`${process.env.REACT_APP_API_URL}/task-categories`)
        .then((response) => response.json())
        .then((dataList) => {
          if (Array.isArray(dataList)) {
            if (dataList.length !== 0) {
              this.setState({ categories: dataList });
            }
          }
        })
        .catch((error) => console.error("Error fetching tasks:", error));
    };


    fetch = () => {
      fetch(`${process.env.REACT_APP_API_URL}/requested-by`)
        .then((response) => response.json())
        .then((dataList) => {
          if (Array.isArray(dataList)) {
            if (dataList.length !== 0) {
              this.setState({ requestors: dataList });
            }
          }
        })
        .catch((error) => console.error("Error fetching requestors:", error));
    };

  fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/tasks`)
      .then((response) => response.json())
      .then((dataList) => {

        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {

            this.setState({ tasks: dataList }, ()=> {
              console.log(this.state);
            });
          }
        }
      });
  };

  componentDidMount() {
    this.setState({isLoading:true}, ()=> {
      this.fetchData();
      this.fetchTasks();
      this.fetch();
      this.setState({isLoading: false});
    })

  }

  handleRequestedByChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ requestedBy: selectedValue });
  };

  handleTaskChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ task: selectedValue });
  };

  handleAdditionalNotesChange = value => {
    this.setState( {additionalNotes: value} );
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, error: "" }, async () => {

      const {
        task,
        additionalNotes,
        requestedBy,
        donorFirstName,
        donorLastName,
        nxtLink,
        filename,
        title
      } = this.state;
  
      if (!task.trim() || !additionalNotes.trim() || !requestedBy.trim()) {
        this.setState({ error: "Please fill in all required fields", isLoading: false }, ()=> {
          toast.error('Please fill in all required fields!', {
            position: toast.POSITION.TOP_CENTER,
          });
        });

      }  else {
        try {

  
          // Create an object with the required fields for the API call
          const ticketData = {
            "Requested By": requestedBy,
            "Task": task,
            "Donor First Name": donorFirstName,
            "Donor Last Name": donorLastName,
            "Constituent NXT Link": nxtLink,
            "Additional Notes": additionalNotes,
            "Filename": filename,
            "Title": title
          };
  
          // Make an API call to submit the ticket
          const response = await fetch(`${process.env.REACT_APP_API_URL}/tasks`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // Add any required headers
            },
            body: JSON.stringify(ticketData), // Send the ticket data as JSON
          });
  
          // Handle the response
          if (response.ok) {
            toast.success('Ticket submitted successfully!', {
              position: toast.POSITION.TOP_CENTER,
            });

            // Handle success, update the state accordingly
            this.setState(
              {
                task: "",
                additionalNotes: "",
                donorFirstName: "",
                donorLastName: "",
                nxtLink: "",
                requestedBy: "",
                filename: "",
                title: "",
                isLoading: false,
                error: ""
              },
              () => {
                this.fetchData();
              }
            );
          } else {
            // Handle API error
            throw new Error('API request failed');
          }
        } catch (error) {
          console.error("Error adding result:", error);
          this.setState({ isLoading: false, error: "Something went wrong, try submitting again!" });
        }
      }
    });
  };
  
  

  convertToPST = (timestampString) => {
    const date = new Date(timestampString);
  
    if (isNaN(date.getTime())) {
        return "Invalid Time";
    }
  
    const offsetMinutes = date.getTimezoneOffset();
    const offsetMilliseconds = offsetMinutes * 60 * 1000;
  
    const pstTime = new Date(date - offsetMilliseconds);
    
    const year = pstTime.getFullYear();
    const month = String(pstTime.getMonth() + 1).padStart(2, "0");
    const day = String(pstTime.getDate()).padStart(2, "0");
    
    return `${year}-${month}-${day}`;
};


  handleFileChange = async (e) => {
    this.setState({ isLoading: true});
    const file = e.target.files[0];
    this.setState({ file });


    console.log(file);
    console.log(file.name);

    const requestBody = {
      filename: file.name
    };

    try {
      // Call the API endpoint to get the file upload details
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tasks/file`, requestBody);

      const { url, fields } = response.data;
      const tempUrl = url;

      // Prepare form data with the file to be uploaded
      const formData = new FormData();
      Object.entries({ ...fields, file }).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // Perform the file upload using the obtained URL and fields
      await axios.post(tempUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      this.setState({filename: file.name}, ()=> {
        toast.success('File upload successfully!', {
          position: toast.POSITION.TOP_CENTER,
        });
      })



    } catch (error) {
      // Handle error scenarios
      console.error('Error uploading file:', error);

      toast.error('File upload failed!', {
          position: toast.POSITION.TOP_CENTER,
        });

    }

    this.setState({ isLoading: false});
  };

  handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    const fieldId = e.target.id;

    this.setState({ [fieldId]: selectedValue });
  };
  

  render() {
    const {statuses, status} = this.state;

    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Add a Ticket</span>
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.handleSubmit} className="form-validate">

                  <RequestedBy categories={this.state.requestors} value={this.state.requestedBy} handleChange={this.handleRequestedByChange} />
                  <TaskDropdown categories={this.state.categories} value={this.state.task} handleChange={this.handleTaskChange} />
                  <p>
                    <label htmlFor="title">
                      Title
                    </label>
                    <input
                      type="text"
                      id="title"
                      name="title"
                      value={this.state.title}
                      onChange={(e) =>
                        this.setState({
                          title: e.target.value 
                        },
                        )
                      }
                    />
                  </p>

                  <AdditionalNotes text={this.state.additionalNotes} handleChange={this.handleAdditionalNotesChange} />

                  <p>
                    <label htmlFor="firstName">
                      Donor First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={this.state.donorFirstName}
                      onChange={(e) =>
                        this.setState({
                          donorFirstName: e.target.value 
                        },
                        )
                      }
                    />
                  </p>
                  <p>
                    <label htmlFor="lastName">Donor Last Name</label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={this.state.donorLastName}
                      onChange={(e) =>
                        this.setState({
                          donorLastName: e.target.value 
                        },
                        )
                      }
                    />
                  </p>
                  <p>
                    <label htmlFor="nxtLink">Constituent NXT Link</label>
                    <input
                      type="text"
                      id="nxtLink"
                      name="nxtLink"
                      value={this.state.nxtLink}
                      onChange={(e) =>
                        this.setState({
                          nxtLink: e.target.value 
                        },
                        )
                      }
                    />
                  </p>
                  <p> 
                  <input
                      type="file"
                      id="fileUpload"
                      name="fileUpload"
                      onChange={this.handleFileChange}
                    />

                  </p>

                  <p className="auth-submit">
                    <input type="submit" value="Submit a Ticket" />
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Your Tickets</span>
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form className="form-validate">
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label">Status</label>
                    <span className="contactform-input">
                      <Form.Control
                        id="status"
                        as="select"
                        value={status}
                        onChange={this.handleOptionChange}
                      >
                        {statuses.map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </Form.Control>
                    </span>
                  </p>
                </form>
              </div>
            </div>

            {this.state.tasks.length > 0 && (
              <div className="cart-items-wrap">
                <table className="cart-items">
                  <thead>
                    <tr>
                      <td className="cart-image"></td>
                      <td className="cart-ttl">Requested By (ID)</td>
                      <td className="cart-ttl">Task</td>
                      <td className="cart-price">Date Requested</td>
                      <td className="cart-ttl">Response</td>
                      <td className="cart-ttl" style={{ textAlign: "right" }}>Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.tasks
                      .slice() // Create a shallow copy of the array to avoid mutating the original
                      .sort((a, b) => b.ID - a.ID) // Sort by the 'ID' field in descending order
                      .filter((task) => {
                        if (status === "All") {
                          return true;
                        }

                        return status === task.Status;
                      })
                      .map((result, index) => (
                        <tr key={index}>
                          <td className="cart-image">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleDelete(e, result.ID);
                              }}
                            >
                              <img src={Handshake} alt="Handshake" />
                            </a>
                          </td>
                          <td className="cart-ttl">{result.RequestedBy} ({result.ID})</td>
                          <td className="cart-ttl">{result.Task}</td>
                          
                          <td className="cart-price">
                            {this.convertToPST(result.DateRequested)}
                          </td>
                          <td
                            className="cart-ttl"
                            dangerouslySetInnerHTML={{
                              __html: result.Response,
                            }}
                          ></td>

                          <Status style={{ textAlign: "right", width: "100%" }} status={result.Status} />

                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </section>
          {this.state.isLoading && (<Spinner />)}
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default Tickets;