import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Add Quill styles

class AdditionalNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const editorStyle = {
        height: '300px', // Adjust this value to increase the height
      };

    return (
      <p className="contactform-field contactform-textarea" style={{height: "350px"}}>
        <label className="contactform-label">
            Additional Notes <span className="required">*</span>
        </label>
        <span className="contactform-input">
        <ReactQuill
         style={editorStyle}
          value={this.props.text}
          onChange={this.props.handleChange}
        />
        </span>

      </p>
    );
  }
}

export default AdditionalNotes;
