import React, { Component } from 'react';
import { Form } from 'react-bootstrap'; // Assuming you're using react-bootstrap

class RequestedBy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      donationType: ''
    };
  }


  handleDonationChange = (event) => {
    // Handle the donation type change here
    this.setState({ donationType: event.target.value });
  }



  render() {
    return (
      <p className="contactform-field contactform-text">
        <label className="contactform-label">Requested By <span className="required">*</span></label>
        <span className="contactform-input">
          <Form.Control
            id="requestedBy"
            as="select"
            value={this.props.value}
            onChange={this.props.handleChange}
          >
            <option
              value=""
              disabled
              hidden
              style={{ color: "#999" }}
            >
              &#x2015; Choose an option &#x2015;
            </option>
            {this.props.categories.map(category => (
              <option key={category.ID} value={category.Name}>
                {category.Name}
              </option>
            ))}
          </Form.Control>
        </span>
      </p>
    );
  }
}

export default RequestedBy;
