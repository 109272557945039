import React, { Component } from "react";
import "./css/TaskManager.css"; // Create a separate CSS file for styling
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogoHeader from "./LogoHeader2";
import Handshake from "./img/handshake.png";
import Spinner from "./Spinner";
import { confirmAlert } from 'react-confirm-alert';
import axios from "axios";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS for styling

class RequestedByManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      data: [],
      isLoading: false,
      name: "",
      department: "",
      newName: "",
      newID: null,
      newDepartment: ""
    };
  }

  // Method to handle form submission
  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, error: "" }, async () => {
      const { name, department } = this.state;

      if (!name.trim()) {
        this.setState({ error: "Please fill in all required fields", isLoading: false }, () => {
          toast.error('Please fill in all required fields!', {
            position: toast.POSITION.TOP_CENTER,
          });
        });
      } else {
        try {
          const data = {
            Name: name,
            Department: department
          };

          const response = await fetch(`${process.env.REACT_APP_API_URL}/requested-by`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // Add any required headers
            },
            body: JSON.stringify(data),
          });

          if (response.ok) {
            toast.success('New Requester added successfully!', {
              position: toast.POSITION.TOP_CENTER,
            });

            this.setState({
              name: "",
              department: "",
              isLoading: false,
              error: ""
            }, () => {
              this.fetch();
            });
          } else {
            throw new Error('API request failed');
          }
        } catch (error) {
          console.error("Error adding requestor:", error);
          this.setState({ isLoading: false, error: "Something went wrong, try again!" });
        }
      }
    });
  };

  fetch = () => {
    fetch(`${process.env.REACT_APP_API_URL}/requested-by`)
      .then((response) => response.json())
      .then((dataList) => {
        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            this.setState({ data: dataList });
          }
        }
      })
      .catch((error) => console.error("Error fetching requestors:", error));
  };

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.fetch();
      console.log(this.state);
      this.setState({ isLoading: false });
    });
  }

  handleEditSubmit = () => {
    const userInput = {
        ID: this.state.newID,
        Name: this.state.newName,
        Department: this.state.newDepartment
    };

    console.log(userInput);

    if (this.state.newName) {
      // Call the API to update the category name
      fetch(`${process.env.REACT_APP_API_URL}/requested-by`, {
        method: 'PUT',
        body: JSON.stringify(userInput),
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(() => {
          // On successful API call, show a success notification
          toast.success(`${this.state.newName} updated!`);

          this.fetch();
        })
        .catch((error) => {
          // Handle API call errors here
          toast.error('Failed to update name');
        });
    } else {
      // Notify the user if they did not provide a new name
      toast.warn('No new name provided');
    }
  }


handleEdit = (event, id, name, department) => {
    event.preventDefault();
  
    this.setState({ newName: name, newID: id, newDepartment: department });
  
    confirmAlert({
      title: '',
      message: 'Please enter the new information:',
      buttons: [
        {
          label: 'Submit',
          className: 'green-button', // Apply custom class to 'No' button
          onClick: () => {
            this.handleEditSubmit();
          },
        },
        {
          label: 'Cancel',
          className: 'red-button', // Apply custom class to 'No' button
          onClick: () => {}, // Do nothing on cancel
        },
      ],
      childrenElement: (props) => {
        const myThis = this;

        return ( <div>
            <input
              placeholder="Name"
              type="text"
              value={myThis.myName}
              autoFocus
              onChange={(e) => {
                myThis.setState({ newName: e.target.value }, () => {
                  myThis.myName = e.target.value;
                });
              }}
            /> <br />
            <input
              style={{ marginTop: '5px' }} // Adding a margin-top inline
              placeholder="Department"
              type="text"
              value={myThis.myDepartment}
              autoFocus
              onChange={(e) => {
                myThis.setState({ newDepartment: e.target.value }, () => {
                  myThis.newDepartment = e.target.value;
                });
              }}
            />
          </div>
          

      );
    }
    });
  };
  

  handleDelete = (event, id) => {
    event.preventDefault();
  
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this requestor?',
      buttons: [
        {
          label: 'Yes',
          className: 'green-button', // Apply custom class to 'No' button
          onClick: async () => {
            const apiUrl = `${process.env.REACT_APP_API_URL}/requested-by/${id}`;
  
            try {
              await axios.delete(apiUrl);
  
              toast.success('Requestor Deleted!', {
                position: toast.POSITION.TOP_CENTER,
              });
  
              console.log("Requestor deleted successfully");
              this.fetch();
            } catch (error) {
              console.error("Error deleting Requestor", error);
            }
          }
        },
        {
          label: 'No',
          className: 'red-button', // Apply custom class to 'No' button
          onClick: () => console.log("Deletion canceled by user")
        }
      ]
    });
  };

  render() {
    return (
      <>
      <LogoHeader />
      <main>
      <section className="container stylization maincont">
      <h1 className="main-ttl">
              <span>Add a Requestor</span>
       </h1>

       <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.handleSubmit} className="form-validate">
                  <p>
                    <label htmlFor="category">
                      Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="category"
                      name="category"
                      value={this.state.name}
                      onChange={(e) =>
                        this.setState({
                            name: e.target.value 
                        },
                        )
                      }
                    />
                  </p>
                  <p>
                    <label htmlFor="department">
                      Department
                    </label>
                    <input
                      type="text"
                      id="department"
                      name="department"
                      value={this.state.department}
                      onChange={(e) =>
                        this.setState({
                            department: e.target.value 
                        },
                        )
                      }
                    />
                  </p>

                  <p className="auth-submit">
                    <input type="submit" value="Add a Requestor" />
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>


      </section>
      <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Current Requestors</span>
            </h1>
            {this.state.data.length > 0 && (
              <div className="cart-items-wrap">
                <table className="cart-items">
                  <thead>
                    <tr>
                      <td className="cart-image"></td>
                      <td className="cart-ttl">Name</td>
                      <td className="cart-ttl">Department</td>
                      <td className="cart-del"></td>
                      <td className="cart-del"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data
                      .slice() // Create a shallow copy of the array to avoid mutating the original
                      .map((result, index) => (
                        <tr key={index}>
                          <td className="cart-image">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleDelete(e, result.ID);
                              }}
                            >
                              <img src={Handshake} alt="Handshake" />
                            </a>
                          </td>
                          <td className="cart-ttl">{result.Name}</td>
                          <td className="cart-ttl">{result.Department}</td>
                          <td className="cart-del">
                            <a
                              className="cart-edit"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleEdit(e, result.ID, result.Name, result.Department);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>
                          <td className="cart-del">
                            <a
                              className="cart-remove"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleDelete(e, result.ID);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>

                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </section>
      {this.state.isLoading && (<Spinner />)}
      </main>
      <ToastContainer />
      </>
    );
  }
}

export default RequestedByManager;
