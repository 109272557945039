import React, { Component } from "react";
import "./css/Pledges.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader2";
import Handshake from "./img/handshake.png";
import Spinner from "./Spinner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import the CSS for styling
import * as XLSX from "xlsx";
import { STATUSES } from "./const";
import SupportTicketList from "./SupportTicketList";

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      data: [],
      isLoading: false,
      statuses: ["All", ...STATUSES],
      status: STATUSES[0],
      tasks: [],
      responseText: "",
      expandedRowID: null
    };
  }

  fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/tasks`)
      .then((response) => response.json())
      .then((dataList) => {
        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            this.setState({ tasks: dataList }, () => {
              console.log(this.state);
            });
          }
        }
      });
  };

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.fetchData();
      this.setState({ isLoading: false });
    });
  }

  getPreviousStatus = (currentStatus) => {
    const statuses = STATUSES;

    const currentIndex = statuses.indexOf(currentStatus);
    const firstIndex = 0;

    if (currentIndex === -1) {
      // If the current status is not found in the array, return the initial status
      return statuses[0];
    } else if (currentIndex === firstIndex) {
      // If the current status is the first one, return the same status
      return statuses[firstIndex];
    } else {
      // Return the previous status in the array
      return statuses[currentIndex - 1];
    }
  };

  handleStatusDemote = async (event, id) => {
    event.preventDefault();
    const currentTask = this.state.tasks.find((item) => item.ID === id);
    const nextStatus = this.getPreviousStatus(currentTask.Status);

    // Replace 'your-api-endpoint' with the actual API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/tasks`;

    const requestBody = {
      ID: id,
      Status: nextStatus,
    };

    try {
      // Make a PUT request to mark the wine as picked up
      await axios.put(apiUrl, requestBody);
      // Handle a successful response, e.g., show a success message
      console.log("Status updated successfully");
      await this.fetchData();
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error updating status", error);
    }
  };

  handleDelete = (event, id) => {
    event.preventDefault();

    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this task?",
      buttons: [
        {
          label: "Yes",
          className: "green-button", // Apply custom class to 'No' button
          onClick: async () => {
            const apiUrl = `${process.env.REACT_APP_API_URL}/task/${id}`;

            try {
              await axios.delete(apiUrl);

              toast.success("Task Deleted!", {
                position: toast.POSITION.TOP_CENTER,
              });

              console.log("Task deleted successfully");
              await this.fetchData();
            } catch (error) {
              console.error("Error deleting task", error);
            }
          },
        },
        {
          label: "No",
          className: "red-button", // Apply custom class to 'No' button
          onClick: () => console.log("Deletion canceled by user"),
        },
      ],
    });
  };

  convertToPST = (timestampString) => {
    const date = new Date(timestampString);

    if (isNaN(date.getTime())) {
      return "Invalid Time";
    }

    const offsetMinutes = date.getTimezoneOffset();
    const offsetMilliseconds = offsetMinutes * 60 * 1000;

    const pstTime = new Date(date - offsetMilliseconds);

    const year = pstTime.getFullYear();
    const month = String(pstTime.getMonth() + 1).padStart(2, "0");
    const day = String(pstTime.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  getNextStatus = (currentStatus) => {
    const statuses = [
      "To Do",
      "In Progress",
      "Blocked",
      "Completed",
      "Cancelled",
      "Need More Information",
      "Reopened",
    ];

    const currentIndex = statuses.indexOf(currentStatus);
    const lastIndex = statuses.length - 1;

    if (currentIndex === -1) {
      // If the current status is not found in the array, return the initial status
      return statuses[0];
    } else if (currentIndex === lastIndex) {
      // If the current status is the last one, return the same status
      return statuses[lastIndex];
    } else {
      // Return the next status in the array
      return statuses[currentIndex + 1];
    }
  };

  handlePromoteStatus = async (event, id) => {
    event.preventDefault();
    const currentTask = this.state.tasks.find((item) => item.ID === id);
    const nextStatus = this.getNextStatus(currentTask.Status);

    // Replace 'your-api-endpoint' with the actual API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/tasks`;

    const requestBody = {
      ID: id,
      Status: nextStatus,
    };

    try {
      // Make a PUT request to mark the wine as picked up
      await axios.put(apiUrl, requestBody);
      // Handle a successful response, e.g., show a success message
      console.log("Status updated successfully");
      await this.fetchData();
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error updating status", error);
    }
  };

  getComplementaryColorForTier = (tier) => {
    const tierColors = {
      Tier1: "#FF5733",
      Tier2: "#FFC300",
      Tier3: "#C70039",
      Unspecified: "#2dccd3", // Default color if tier is Unspecified
    };
    const tierColor = tierColors[tier] || tierColors["Unspecified"];

    // Convert hex color to RGB
    const hexToRgb = (hex) =>
      hex.match(/\w\w/g).map((str) => parseInt(str, 16));

    const [r, g, b] = hexToRgb(tierColor);

    // Calculate complementary color
    const complementaryColor = `rgb(${255 - r}, ${255 - g}, ${255 - b})`;
    return complementaryColor;
  };
  getNextTier = (currentTier) => {
    switch (currentTier) {
      case "Unspecified":
        return "Tier1";
      case "Tier1":
        return "Tier2";
      case "Tier2":
        return "Tier3";
      case "Tier3":
        return "Unspecified";
      default:
        return "Unspecified"; // If an unknown tier is provided, default to Unspecified
    }
  };

  updateTier = async (e, id, tier) => {
    e.preventDefault();
    const nextTier = this.getNextTier(tier);

    // Replace 'your-api-endpoint' with the actual API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/tasks/tier`;

    const requestBody = {
      ID: id,
      Tier: nextTier,
    };

    try {
      // Make a PUT request to mark the wine as picked up
      await axios.put(apiUrl, requestBody);
      // Handle a successful response, e.g., show a success message
      console.log("Tier updated successfully");
      await this.fetchData();
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error updating tier", error);
    }
  };

  handleFileDownload = async (e, filename) => {
    e.preventDefault();

    const apiUrl = `${process.env.REACT_APP_API_URL}/tasks/file/${filename}`;

    const response = await axios.get(apiUrl);

    const { url } = response.data;

    const modifiedUrl = url;

    console.log(modifiedUrl);

    const newResponse = await fetch(modifiedUrl);
    const blob = await newResponse.blob();

    // Create a temporary anchor element
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(new Blob([blob]));
    downloadLink.setAttribute("download", filename); // Change 'file' to set the filename

    // Simulate click on the anchor to trigger download
    downloadLink.click();
  };


  exportData = (e) => {
    e.preventDefault();

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/tasks`,
    }).then((response) => {
      const results = response.data;

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Convert attendees data to worksheet
      const ws = XLSX.utils.json_to_sheet(results);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Tickets");

      // Generate a unique filename for the Excel file
      const filename = `all_tickets_${new Date().toISOString()}.xlsx`;

      // Save the Excel file and trigger download
      XLSX.writeFile(wb, filename);
    });
  };

  handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    const fieldId = e.target.id;

    this.setState({ [fieldId]: selectedValue });
  };

  updateExpandRow = (e, taskID) => {
    e.preventDefault();

    if (this.state.expandedRowID === taskID) {
      this.setState({
        expandedRowID: null
      });

      return;
    }

    // Find the item with matching receiptID
    const foundItem = this.state.tasks.find(
      (task) => task.ID === taskID
    );


    if (foundItem) {
      this.setState({
        expandedRowID: taskID,
        editedItem: foundItem
      });
    }
  };

  handleTaskUpdate = () => {

  }

  updateTask = () => {

  }

  render() {
    const { statuses, status, tasks, expandedRowID, isLoading, responseText } = this.state;

    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization">
            <p>
              <button onClick={this.exportData} disabled={this.state.isLoading}>
                Export ALL Support Ticket Data As Excel
              </button>
            </p>
          </section>
          <SupportTicketList
            tasks={tasks}
            statuses={statuses}
            status={status}
            handleOptionChange={this.handleOptionChange}
            handleStatusDemote={this.handleStatusDemote}
            handlePromoteStatus={this.handlePromoteStatus}
            handleDelete={this.handleDelete}
            expandedRowID={expandedRowID}
            updateExpandRow={this.updateExpandRow}
            isLoading={isLoading}
            toast={toast}
            handleTaskUpdate={this.handleTaskUpdate}
            updateTask={this.handleSubmit}
            fetchData={this.fetchData}
          />
          {this.state.isLoading && <Spinner />}
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default Admin;