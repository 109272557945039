const getToken = async () => {
  if (
    !localStorage.getItem("token") ||
    localStorage.getItem("token") === "null"
  ) {
    return false;
  }

  return localStorage.getItem("token");
};

export const fetchUserData = async () => {
  const token = await getToken();

  if (token) {
    const response = await fetch(
      `https://dev.api.bigsisters.bc.ca/check-token`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ access_token: token }), // Include the token in the request body
      }
    );
    if (response.ok) {
      const data = await response.json();

      if (data["success"]) {
        return data["data"];
      }
    }
  }

  localStorage.setItem("token", "");
  return false;
};