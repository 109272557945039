import React from "react";
import logo from "./img/logo.png"

function AuthLogoHeader() {

  return (
    <header className="header">
    <div className="header-middle">
      <div className="container header-middle-cont">
        <div className="toplogo">
          <a href="/">
            <img src={logo} alt="Big Sisters of BC Lower Mainland" />
          </a>
        </div>
        <div className="shop-menu social-icons">
          <ul>
          </ul>
        </div>
      </div>
    </div>
    </header>
  );
}

export default AuthLogoHeader;