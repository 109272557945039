import "./css/App.css";
import NotFound from "./NotFound";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import React, { useEffect } from "react";
import Tickets from "./Tickets";
import Admin from "./Admin"
import TaskManager from "./TaskManager";
import RequestedByManager from "./RequestedByManager";
import Auth from './Auth';

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token")
  const groups = localStorage.getItem("groups")

  useEffect(() => {
    if (!token) {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set("prevUrl", location.pathname);
      console.log(`/login?${currentUrl.searchParams}`);

      if (!location.pathname.includes("login")) {
        navigate(`/login?${currentUrl.searchParams}`);
      }
    }
  }, [token, location, navigate]);

  useEffect(() => {
    // Redirect logic
    if (window.location.pathname === "/receipts") {
      window.location.href = "https://hermes.bigsistersbc.ca";
    }
  }, []);

  const getHomePage = () => {
    if (groups && groups.includes("Admin")) {
      return <Admin />;
    }

    return <Tickets />;
  }

  return (
    <div className="App">
      <Helmet>
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
      </Helmet>
      <Routes>
        <Route path="/" element={getHomePage()} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/task-manager" element={<TaskManager />} />
        <Route path="/requested-by" element={<RequestedByManager />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/login" element={<Auth />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;