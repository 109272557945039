// SupportTicketList.js
import React from "react";
import Form from "react-bootstrap/Form";
import TaskForm from "./TaskForm";

class SupportTicketList extends React.Component {
  render() {
    const {
      tasks,
      statuses,
      status,
      handleOptionChange,
      handleStatusDemote,
      handlePromoteStatus,
      handleDelete,
      updateExpandRow,
      expandedRowID,
      isLoading,
      toast,
      handleTaskUpdate,
      updateTask,
      fetchData
    } = this.props;

    return (
      <section className="container stylization maincont">
        <h1 className="main-ttl">
          <span>List of Support Tickets</span>
        </h1>

        <div className="auth-wrap">
          <div className="auth-col">
            <form className="form-validate">
              <p className="contactform-field contactform-text">
                <label className="contactform-label">Status</label>
                <span className="contactform-input">
                  <Form.Control
                    id="status"
                    as="select"
                    value={status}
                    onChange={handleOptionChange}
                  >
                    {statuses.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </Form.Control>
                </span>
              </p>
            </form>
          </div>
        </div>

        {tasks.length > 0 && (
          <div className="cart-items-wrap">
            <table className="cart-items">
              <thead>
                <tr>
                  <td className="cart-ttl"></td>
                  <td className="cart-ttl">
                    ID / Requested By / Task / Donor Name
                  </td>
                  <td className="cart-ttl">Title</td>
                  <td className="cart-ttl">Tier</td>
                  <td className="cart-ttl">Status</td>
                  <td className="cart-ttl">Submitted Date</td>
                  <td className="cart-del"></td>
                  <td className="cart-del"></td>
                  <td className="cart-del"></td>
                  <td className="cart-del"></td>
                </tr>
              </thead>
              <tbody>
                {tasks
                  .slice()
                  .sort((a, b) => b.ID - a.ID)
                  .filter((task) => {
                    if (status === "All") {
                      return true;
                    }

                    return status === task.Status;
                  })
                  .map((task) => (<>
                    <tr key={task.ID}>
                      <td className="cart-image">
                        {/* Add any image or icon here if needed */}
                      </td>
                      <td className="cart-ttl">
                      <b style={{ fontWeight: "bold" }}>{task.ID}</b><br/>{task.RequestedBy}<br/>{task.Task}<br/>
                        {task.DonorFirstName} {task.DonorLastName}
                      </td>
                      <td className="cart-price">{task.Title}</td>
                      <td className="cart-price">{task.Tier}</td>
                      <td className="cart-quantity">{task.Status}</td>
                      <td className="cart-delivery">{task.DateRequested}</td>
                      <td className="cart-del">
                        <a
                          className="cart-pickedup"
                          href="/"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            handleStatusDemote(e, task.ID);
                          }}
                          role="button" // Add role="button" to indicate it's a button
                        >
                          {"\u00A0"}
                        </a>
                      </td>
                      <td className="cart-del">
                        <a
                          className="cart-paid"
                          href="/"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            handlePromoteStatus(e, task.ID);
                          }}
                          role="button" // Add role="button" to indicate it's a button
                        >
                          {"\u00A0"}
                        </a>
                      </td>

                      <td className="cart-del">
                        <a
                          className="cart-remove"
                          href="/"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            handleDelete(e, task.ID);
                          }}
                          role="button" // Add role="button" to indicate it's a button
                        >
                          {"\u00A0"}
                        </a>
                      </td>
                      <td className="cart-del">
                        <a
                          className="cart-details"
                          href="/"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            updateExpandRow(e, task.ID);
                          }}
                          role="button" // Add role="button" to indicate it's a button
                        >
                          {"\u00A0"}
                        </a>
                      </td>
                    </tr>
                    <>
                     {expandedRowID === task.ID && (
                    <tr className="notes-row">
                       <td className="cart-image">
                        {/* Add any image or icon here if needed */}
                      </td>
                      <td colSpan="5">

                          <TaskForm
                            isLoading={isLoading}
                            toast={toast}
                            editedItem={task}
                            handleItemUpdate={handleTaskUpdate}
                            updateItem={updateTask}
                            fetchData={fetchData}
                          />
                      </td>
                    </tr>
                  )}
                    </>
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </section>
    );
  }
}

export default SupportTicketList;