import React, { Component } from "react";
import "./css/TaskManager.css"; // Create a separate CSS file for styling
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogoHeader from "./LogoHeader2";
import Handshake from "./img/handshake.png";
import Spinner from "./Spinner";
import { confirmAlert } from 'react-confirm-alert';
import axios from "axios";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS for styling

class TaskManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      tasks: [],
      isLoading: false,
      taskName: "",
      newCategoryName: "",
      newCategoryID: null
    };
  }

  // Methods for handling changes in input fields
  handleTaskNameChange = (e) => {
    this.setState({ taskName: e.target.value });
  };

  // Method to handle form submission
  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, error: "" }, async () => {
      const { taskName } = this.state;

      if (!taskName.trim()) {
        this.setState({ error: "Please fill in all required fields", isLoading: false }, () => {
          toast.error('Please fill in all required fields!', {
            position: toast.POSITION.TOP_CENTER,
          });
        });
      } else {
        try {
          const taskData = {
            Category: taskName,
          };

          const response = await fetch(`${process.env.REACT_APP_API_URL}/task-categories`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // Add any required headers
            },
            body: JSON.stringify(taskData),
          });

          if (response.ok) {
            toast.success('Task added successfully!', {
              position: toast.POSITION.TOP_CENTER,
            });

            this.setState({
              taskName: "",
              isLoading: false,
              error: ""
            }, () => {
              this.fetchTasks();
            });
          } else {
            throw new Error('API request failed');
          }
        } catch (error) {
          console.error("Error adding task:", error);
          this.setState({ isLoading: false, error: "Something went wrong, try again!" });
        }
      }
    });
  };

  // Method to fetch tasks
  fetchTasks = () => {
    fetch(`${process.env.REACT_APP_API_URL}/task-categories`)
      .then((response) => response.json())
      .then((dataList) => {
        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            this.setState({ tasks: dataList });
          }
        }
      })
      .catch((error) => console.error("Error fetching tasks:", error));
  };

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.fetchTasks();
      console.log(this.state);
      this.setState({ isLoading: false });
    });
  }

  handleEditSubmit = () => {
    const userInput = {
        ID: this.state.newCategoryID,
        Category: this.state.newCategoryName
    };

    console.log(userInput);

    if (this.state.newCategoryName) {
      // Call the API to update the category name
      fetch(`${process.env.REACT_APP_API_URL}/task-categories`, {
        method: 'PUT',
        body: JSON.stringify(userInput),
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(() => {
          // On successful API call, show a success notification
          toast.success(`Category name updated to ${this.state.newCategoryName}`);

          this.fetchTasks();
        })
        .catch((error) => {
          // Handle API call errors here
          toast.error('Failed to update category name');
        });
    } else {
      // Notify the user if they did not provide a new name
      toast.warn('No new name provided');
    }
  }


handleEdit = (event, id, category) => {
    event.preventDefault();
  
    this.setState({ newCategoryName: category, newCategoryID: id });
  
    confirmAlert({
      title: '',
      message: 'Please enter the new category name:',
      buttons: [
        {
          label: 'Submit',
          className: 'green-button', // Apply custom class to 'No' button
          onClick: () => {
            this.handleEditSubmit();
          },
        },
        {
          label: 'Cancel',
          className: 'red-button', // Apply custom class to 'No' button
          onClick: () => {}, // Do nothing on cancel
        },
      ],
      childrenElement: (props) => {
        const myThis = this;

        return (
        <input
          type="text"
          value={myThis.myValue}
          autoFocus
          onChange={(e) => { 
            myThis.setState({ newCategoryName: e.target.value }, () => {
                myThis.myValue = e.target.value;
            });
        }}
        />
      );
    }
    });
  };
  

  handleDelete = (event, id) => {
    event.preventDefault();
  
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this task category?',
      buttons: [
        {
          label: 'Yes',
          className: 'green-button', // Apply custom class to 'No' button
          onClick: async () => {
            const apiUrl = `${process.env.REACT_APP_API_URL}/task-categories/${id}`;
  
            try {
              await axios.delete(apiUrl);
  
              toast.success('Task Category Deleted!', {
                position: toast.POSITION.TOP_CENTER,
              });
  
              console.log("Task Category deleted successfully");
              this.fetchTasks();
            } catch (error) {
              console.error("Error deleting task", error);
            }
          }
        },
        {
          label: 'No',
          className: 'red-button', // Apply custom class to 'No' button
          onClick: () => console.log("Deletion canceled by user")
        }
      ]
    });
  };

  render() {
    return (
      <>
      <LogoHeader />
      <main>
      <section className="container stylization maincont">
      <h1 className="main-ttl">
              <span>Add a Task Category</span>
       </h1>

       <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.handleSubmit} className="form-validate">
                  <p>
                    <label htmlFor="category">
                      Category Name
                    </label>
                    <input
                      type="text"
                      id="category"
                      name="category"
                      value={this.state.taskName}
                      onChange={(e) =>
                        this.setState({
                            taskName: e.target.value 
                        },
                        )
                      }
                    />
                  </p>
    

                  <p className="auth-submit">
                    <input type="submit" value="Create a Task Category" />
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>


      </section>
      <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Current Task Categories</span>
            </h1>
            {this.state.tasks.length > 0 && (
              <div className="cart-items-wrap">
                <table className="cart-items">
                  <thead>
                    <tr>
                      <td className="cart-image"></td>
                      <td className="cart-ttl">Category Name</td>
                      <td className="cart-del"></td>
                      <td className="cart-del"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.tasks
                      .slice() // Create a shallow copy of the array to avoid mutating the original
                      .map((result, index) => (
                        <tr key={index}>
                          <td className="cart-image">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleDelete(e, result.ID);
                              }}
                            >
                              <img src={Handshake} alt="Handshake" />
                            </a>
                          </td>
                          <td className="cart-ttl">{result.Category}</td>
                          <td className="cart-del">
                            <a
                              className="cart-edit"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleEdit(e, result.ID, result.Category);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>
                          <td className="cart-del">
                            <a
                              className="cart-remove"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleDelete(e, result.ID);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>

                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </section>
      {this.state.isLoading && (<Spinner />)}
      </main>
      <ToastContainer />
      </>
    );
  }
}

export default TaskManager;
